import React, { ChangeEvent } from 'react';
import { Col, Input, Row, DatePicker, Tooltip, Checkbox } from 'antd';
import debounce from 'lodash.debounce';
import { RouteComponentProps, withRouter } from 'react-router';
import moment, { Moment } from 'moment';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { objectToQuery, queryToObject } from 'common/helpers/filters.helper';
import { CommonLayout } from 'common/components/Layouts/CommonLayout';
import { ELayoutSize } from 'common/const/common.const';
import { LocationInput } from 'common/components/Input/LocationInput';
import { ELocationType, ILocationModel } from 'common/helpers/location.helper';
import { simpleDateFormatWithTime, simpleTimeFormat } from 'common/models/dateModels';
import { MentorSelector } from 'entities/User/components/MentorSelector';
import { TrainerSelector } from 'entities/User/components/TrainerSelector';
import { IUserModel } from 'entities/User/User.models';
import { PlayerTable } from 'entities/Player/components/PlayerTable';
import { IPlayerCollectionFilter, tablePlayerConfig, EPlayerStatuses, EPlayerAgeGroups } from 'entities/Player/Player.models';
import { PlayerStatusSelector } from 'entities/Player/components/Selector/PlayerStatusSelector';
import { PlayerAgeGroupSelector } from 'entities/Player/components/Selector/PlayerAgeGroupSelector';
import { AcademySelector } from 'entities/Academy/components/Selector/AcademySelector';
import { IAcademyModel } from 'entities/Academy/Academy.models';
import { PlayerPanelStatistic } from 'entities/Player/components/PlayerPanelStatistic';
import { PlayerRegCompleteSelector } from 'entities/Player/components/Selector/PlayerRegCompleteSelector';
import { PlayerPrivateSelector } from 'entities/Player/components/Selector/PlayerPrivateSelector';

const { RangePicker } = DatePicker;

type AllProps = RouteComponentProps;

interface IComponentState {
  filter: IPlayerCollectionFilter;
}

class PlayerPageComponent extends React.PureComponent<AllProps, IComponentState> {
  public debounceSearch: any;
  public debounceEmailSearch: any;
  public debouncePhoneSearch: any;
  state: IComponentState = {
    filter: {
      pageIndex: 1,
      name: undefined,
      phone: undefined,
      private: undefined,
      mentorFilter: undefined,
      trainerFilter: undefined,
      academyFilter: undefined,
      statuses: undefined,
      ageGroups: undefined,
      dateRegFrom: undefined,
      regComplete: undefined,
      dateRegTo: undefined,
      location: undefined,
      email: undefined,
      hideArchived: false
    }
  };
  constructor(props: AllProps) {
    super(props);
    this.state.filter = { ...this.state.filter, ...queryToObject<IPlayerCollectionFilter>(this.state.filter) };
    this.debounceSearch = debounce(this.autocompleteSearch, 300);
    this.debounceEmailSearch = debounce(this.autocompleteEmailSearch, 300);
    this.debouncePhoneSearch = debounce(this.autocompletePhoneSearch, 300);
  }

  render() {
    const { filter } = this.state;
    const {
      name,
      mentorFilter,
      trainerFilter,
      statuses,
      ageGroups,
      dateRegFrom,
      dateRegTo,
      academyFilter,
      regComplete,
      phone,
      hideArchived,
      location,
      email,
      private: privateValue
    } = filter;

    const fromValue = dateRegFrom ? moment(dateRegFrom) : null;
    const toValue = dateRegTo ? moment(dateRegTo) : null;

    return (
      <CommonLayout typeLayout={ELayoutSize.Large} classStyle="mt-10">
        <Row className="mb-10 width-full" justify="space-between" align="middle">
          <Col span={24}>
            <Row justify="start" align="middle" gutter={[8, 8]}>
              <Col span={4}>
                <Input.Search
                  placeholder="Search by name"
                  defaultValue={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceSearch(e.target.value)}
                />
              </Col>
              <Col span={4}>
                <Input.Search
                  placeholder="Search by email"
                  defaultValue={email}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => this.debounceEmailSearch(e.target.value)}
                />
              </Col>
              <Col span={3}>
                <MentorSelector onChange={this.onChangeMentor} value={mentorFilter} placeholder="Search by mentor" />
              </Col>
              <Col span={3}>
                <AcademySelector onChange={this.onChangeAcademy} value={academyFilter} placeholder="Search by academy" />
              </Col>
              <Col span={3}>
                <TrainerSelector onChange={this.onChangeTrainer} value={trainerFilter} placeholder="Search by coach" />
              </Col>
              <Col span={3}>
                <PlayerStatusSelector onChange={this.onChangeStatus} value={statuses} placeholder="Search by status" />
              </Col>
              <Col span={3}>
                <PlayerAgeGroupSelector onChange={this.onChangeAgeGroups} value={ageGroups} placeholder="Search by age groups" />
              </Col>
              <Col span={3}>
                <PlayerRegCompleteSelector
                  onChange={this.onChangeRegComplete}
                  value={regComplete}
                  placeholder="Select registration status"
                />
              </Col>
              <Col span={4}>
                <RangePicker
                  allowEmpty={[true, true]}
                  showNow={false}
                  showTime={{ format: simpleTimeFormat }}
                  value={[fromValue, toValue]}
                  format={simpleDateFormatWithTime}
                  onChange={this.onChangeTimeDate}
                />
              </Col>
              <Col span={4}>
                <PlayerPrivateSelector value={privateValue} onChange={this.onChangePrivate} placeholder="Select private" />
              </Col>
              <Col span={4}>
                <Tooltip trigger={['focus']} title="Minimum 4 digits" placement="topLeft">
                  <Input.Search
                    maxLength={20}
                    placeholder="Search by phone"
                    defaultValue={phone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => this.debouncePhoneSearch(e.target.value)}
                  />
                </Tooltip>
              </Col>
              <Col span={4}>
                <LocationInput
                  placeholder="Search by location"
                  onChange={this.onChangeLocation}
                  value={location}
                  types={[ELocationType.Country]}
                />
              </Col>
              <Col span={4}>
                <Checkbox checked={hideArchived} onChange={this.onChangeHideArchive}>
                  Hide archived
                </Checkbox>
              </Col>
            </Row>
          </Col>
        </Row>
        <PlayerPanelStatistic />
        <PlayerTable config={tablePlayerConfig} filter={filter} />
      </CommonLayout>
    );
  }

  setFilter = (partialFilter: Partial<IPlayerCollectionFilter>) => {
    const oldFilter = this.state.filter;
    const filter = { ...oldFilter, ...partialFilter };

    this.props.history.replace({ search: objectToQuery(filter) });
    this.setState({ filter });
  };

  onChangeStatus = (statuses?: EPlayerStatuses[]) => {
    this.setFilter({ statuses });
  };

  onChangeHideArchive = (e: CheckboxChangeEvent) => {
    this.setFilter({ hideArchived: e.target.checked });
  };

  onChangeLocation = (location?: ILocationModel) => {
    this.setFilter({ location });
  };

  onChangeAgeGroups = (ageGroups?: EPlayerAgeGroups | EPlayerAgeGroups[]) => {
    if (Array.isArray(ageGroups)) {
      this.setFilter({ ageGroups });
    }
  };

  onChangeRegComplete = (regComplete?: boolean) => {
    this.setFilter({ regComplete });
  };

  onChangePrivate = (privateValue?: boolean) => {
    this.setFilter({ private: privateValue });
  };

  onChangeMentor = (mentorModel?: IUserModel) => {
    const mentorFilter = mentorModel
      ? { id: mentorModel.id, firstName: mentorModel.firstName, lastName: mentorModel.lastName }
      : undefined;

    this.setFilter({ mentorFilter });
  };

  onChangeTrainer = (trainerModel?: IUserModel) => {
    const trainerFilter = trainerModel
      ? { id: trainerModel.id, firstName: trainerModel.firstName, lastName: trainerModel.lastName }
      : undefined;

    this.setFilter({ trainerFilter });
  };

  onChangeAcademy = (academyModel?: IAcademyModel) => {
    const academyFilter = academyModel
      ? {
          id: academyModel?.id,
          name: academyModel?.name,
          referralCode: academyModel.ownReferralCode?.id
        }
      : undefined;

    this.setFilter({ academyFilter });
  };

  autocompleteSearch = (text: string) => {
    const name = text === '' ? undefined : text;
    this.setFilter({ name });
  };

  autocompleteEmailSearch = (text: string) => {
    const email = text === '' ? undefined : text;
    this.setFilter({ email });
  };

  autocompletePhoneSearch = (text: string) => {
    const phone = text === '' || text.length < 4 ? undefined : text;
    this.setFilter({ phone });
  };

  onChangeTimeDate = (dates: [Moment | null, Moment | null] | null) => {
    const fromDate = dates?.[0];
    const toDate = dates?.[1];
    const dateRegFrom = fromDate ? fromDate.toISOString() : undefined;
    const dateRegTo = toDate ? toDate.toISOString() : undefined;

    this.setFilter({ dateRegFrom, dateRegTo });
  };
}

export const PlayerTablePage = withRouter(PlayerPageComponent);
